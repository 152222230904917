import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { IoIosClose } from 'react-icons/io';
import { Button, Drawer } from 'antd';
import Logo from '../../../components/UI/Logo/Logo';
import Text from '../../../components/UI/Text/Text';
import TextLink from '../../../components/UI/TextLink/TextLink';
import Navbar from '../../../components/Navbar/Navbar';
import { AuthContext } from '../../../context/AuthProvider';
import { LayoutContext } from '../../../context/LayoutProvider';
import useWindowSize from '../../../library/hooks/useWindowSize';
import { AGENT_PROFILE_PAGE } from '../../../settings/constant';
import AuthMenu from './AuthMenu';
import MainMenu from './MainMenu';
import MobileMenu from './MobileMenu';
import ProfileMenu from './ProfileMenu';
import NavbarSearch from './NavbarSearch';
import HeaderWrapper, {
  MobileNavbar,
  CloseDrawer,
  AvatarWrapper,
  AvatarImage,
  AvatarInfo,
  LogoArea,
} from './Header.style';

const avatarImg = `http://s3.amazonaws.com/redqteam.com/isomorphic-reloaded-image/profilepic.png`;
const LogoIcon = () => (
<svg style={{ width: 40}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="640.000000pt" height="480.000000pt" viewBox="0 0 640.000000 480.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,480.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M4365 4082 c-153 -230 -334 -484 -462 -650 -96 -124 -456 -487 -588
-594 -61 -49 -300 -188 -323 -188 -5 0 -37 69 -72 153 -80 195 -199 442 -255
532 -52 84 -106 138 -131 133 -20 -4 -23 -7 -125 -182 -369 -631 -1046 -1394
-2118 -2386 -139 -129 -249 -236 -243 -238 18 -6 238 37 482 94 129 30 332 78
450 105 409 96 723 136 976 127 l135 -6 -31 19 c-182 111 -562 259 -726 282
l-88 13 85 58 c438 305 857 762 1154 1259 l58 98 167 -333 c91 -183 196 -382
232 -443 223 -372 519 -702 963 -1072 215 -179 484 -385 495 -378 25 16 0 50
-136 185 -398 396 -701 816 -1006 1393 -43 82 -78 150 -78 152 0 3 21 16 48
29 152 78 355 267 556 521 142 178 190 225 317 310 269 182 637 295 790 244
51 -18 63 -31 179 -204 151 -225 216 -302 335 -398 128 -102 251 -178 525
-327 219 -119 309 -172 413 -242 l57 -39 0 28 c0 22 -49 77 -273 302 -421 425
-651 699 -942 1121 -64 92 -132 167 -340 379 -143 145 -280 280 -305 301 l-45
38 -130 -196z"/>
</g>
</svg>
);

export default function Header() {
  let location = useLocation();
  const [{ searchVisibility }] = useContext(LayoutContext);
  const { loggedIn } = useContext(AuthContext);
  const { width } = useWindowSize();
  const [state, setState] = useState(false);
  const sidebarHandler = () => {
    setState(!state);
  };
  const headerType = location.pathname === '/' ? 'transparent' : 'default';

  return (
    <HeaderWrapper>
      <Sticky
        top={headerType === 'transparent' ? -1 : 0}
        innerZ={10001}
        activeClass="isHeaderSticky"
      >
        {width > 991 ? (
          <Navbar
            logo={
              <>
                {headerType === 'transparent' && <LogoIcon />}
                <Logo
                  withLink
                  linkTo="/"
                  src="/images/logo-alt.svg"
                  title="TRIP LINE"
                />
              </>
            }
            navMenu={<MainMenu />}
            authMenu={<AuthMenu />}
            isLogin={loggedIn}
            avatar={<Logo src={avatarImg} />}
            profileMenu={<ProfileMenu avatar={<Logo src={avatarImg} />} />}
            headerType={headerType}
            searchComponent={<NavbarSearch />}
            location={location}
            searchVisibility={searchVisibility}
          />
        ) : (
          <MobileNavbar className={headerType}>
            <LogoArea>
              <>
                {headerType === 'transparent' && <LogoIcon />}
                <Logo
                  withLink
                  linkTo="/"
                  src="/images/logo-alt.svg"
                  title="TRIP LINE"
                />
              </>
              <NavbarSearch />
            </LogoArea>
            <Button
              className={`hamburg-btn ${state ? 'active' : ''}`}
              onClick={sidebarHandler}
            >
              <span />
              <span />
              <span />
            </Button>
            <Drawer
              placement="right"
              closable={false}
              onClose={sidebarHandler}
              width="285px"
              className="mobile-header"
              open={state}
            >
              <CloseDrawer>
                <button onClick={sidebarHandler}>
                  <IoIosClose />
                </button>
              </CloseDrawer>
              {loggedIn ? (
                <AvatarWrapper>
                  <AvatarImage>
                    <Logo src={avatarImg} />
                  </AvatarImage>
                  <AvatarInfo>
                    <Text as="h3" content="Nova Scotia" />
                    <TextLink
                      link={AGENT_PROFILE_PAGE}
                      content="Ver perfil"
                    />
                  </AvatarInfo>
                </AvatarWrapper>
              ) : (
                <AuthMenu className="auth-menu" />
              )}
              <MobileMenu className="main-menu" />
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
}
