import React from 'react';
import Logo from '../../../components/UI/Logo/Logo';
import Footers from '../../../components/Footer/Footer';
import FooterMenu from './FooterMenu';

const Footer = () => {
  return (
    <Footers
      logo={
        <Logo
          withLink
          linkTo="/"
          src="/images/logo-alt.png"
          title="TRIP LINE"
        />
      }
      menu={<FooterMenu />}
      copyright={`Direitos autorais @ ${new Date().getFullYear()} MyCooper.`}
    />
  );
};

export default Footer;
